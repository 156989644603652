body {
	background: black;
	font-family: "Inter", sans-serif;
	color: white;
	margin: 0;
	padding: 0;
}

.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.container {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	padding: 1rem;
	overflow-y: auto;
	max-height: 100vh;
	scrollbar-width: none; /* For Firefox */
	-ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.container::-webkit-scrollbar {
	display: none; /* For Chrome, Safari, and Opera */
}

h1,
h2 {
	font-size: 2.5rem;
	margin-bottom: 1rem;
}

button {
	background-color: #1a1a1a;
	color: white;
	border: 2px solid white;
	padding: 0.5rem 1rem;
	font-size: 1rem;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

button:hover {
	background-color: #333333;
}

input {
	background-color: #333333;
	color: white;
	border: 1px solid white;
	padding: 0.5rem;
	margin: 0.5rem 0;
	width: calc(100% - 1rem);
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	background: linear-gradient(to bottom, #1a1919, black);
	color: white;
	border-radius: 0.5rem;
	padding: 1rem;
	margin: 0.5rem 0;
	border: 1px solid white;
	font-size: 1rem;
}
